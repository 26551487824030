<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>

html, body, .v-application {
  overflow-y: auto!important;
  min-width: 380px;
}

.v-application a {
  color: #fff!important
}

</style>