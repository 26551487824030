import Vue from 'vue'
import VueRouter from 'vue-router'

// Layouts
import DefaultLayout from '../views/layout/DefaultLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: () => import('../views/pages/HomePage')
      },
      {
        path: '/about',
        name: "AboutPage",
        component: () => import('../views/pages/AboutPage')
      },
      {
        path: '/projects',
        name: 'ProjectsPage',
        component: () => import('../views/pages/ProjectsPage')
      },
      {
        path: '/news',
        name: 'NewsPage',
        component: () => import('../views/pages/NewsPage')
      },
      {
        path: '/contact',
        name: 'ContactPage',
        component: () => import('../views/pages/ContactPage')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
