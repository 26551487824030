<template>
    <v-app>
        <Appbar />
        <v-main>
            <transition name="fade">
                <router-view />
            </transition>
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import Appbar from '../../components/Appbar.vue'
import Footer from '../../components/Footer.vue'

export default {
    name: 'DefaultLayout',
    components: {
        Appbar,
        Footer
    }
}
</script>

<style scoped>

main {
    width: 100%;
}

.fade-enter-active {
    animation: forwards fadeIn 1s;
}

.fade-leave-active {
    animation: forwards fadeIn 0.5s;
    animation-direction: reverse;
}

.fade-enter {
    opacity: 0;
}

.fade-leave-to {
    opacity: 0;
}

@keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:0;}
  100% {opacity:1;}
}

</style>