<template>
	<header class="fix-top">
		<div ref="navbar_header" class="navbar-header">
			<v-container>
				<v-row>
					<v-col cols="3" sm="3" lg="3">
						<div class="navbar-header-title">
							<img
								alt="Abismo Studios logo white"
								@click="homePage()"
								src="../assets/img/logo_white.png"
							/>
						</div>
					</v-col>
					<v-col
						class="d-none d-md-none d-lg-block"
						cols="6"
						sm="6"
						lg="6"
					>
						<div class="navbar-header-links">
							<div class="link-menu d-none d-md-none d-lg-block">
								<a
									v-for="(item, i) in this.menuLinks"
									:key="i"
									:href="'/#' + item.href"
									:class="{ active: getNameRoute(item.name) }"
								>
									{{ item.title }}
								</a>
							</div>
						</div>
					</v-col>
					<v-col
						class="d-none d-md-none d-lg-block"
						cols="3"
						sm="3"
						lg="3"
					>
						<div class="navbar-header-rede-links">
							<div class="mt-5 d-none d-md-none d-lg-block">
								<v-btn class="mr-4" href="https://github.com/AbismoStudios" target="_blank" icon>
									<v-icon>mdi-github</v-icon>
								</v-btn>
								<v-btn class="mr-4" href="https://www.youtube.com/channel/UCLjVJbF5m3rkICC3-mCKWoA" target="_blank" icon>
									<v-icon>mdi-youtube</v-icon>
								</v-btn>
								<v-btn href="https://twitter.com/AbismoStudios" target="_blank" icon>
									<v-icon>mdi-twitter</v-icon>
								</v-btn>
							</div>
						</div>
					</v-col>
					<v-col
						class="d-block d-md-block d-lg-none"
						cols="9"
						sm="9"
						lg="9"
					>
						<div class="navbar-header-rede-links mt-3">
							<v-btn
								@click="menu = true"
								x-large
								class="d-block d-md-block d-lg-none"
								icon
							>
								<v-icon x-large>mdi-menu</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<v-navigation-drawer v-model="menu" fixed temporary>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-h6">
						Menu
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider />
			<v-list dense nav>
				<v-list-item
					v-for="(item, i) in this.menuLinks"
					:key="i"
					link
					:to="item.href"
				>
					<v-list-item-content>
						{{ item.title }}
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<div class="mt-5 text-center">
				<v-btn class="mr-4" href="https://github.com/AbismoStudios" target="_blank" icon>
					<v-icon>mdi-github</v-icon>
				</v-btn>
				<v-btn class="mr-4" href="https://www.youtube.com/channel/UCLjVJbF5m3rkICC3-mCKWoA" target="_blank" icon>
					<v-icon>mdi-youtube</v-icon>
				</v-btn>
				<v-btn href="https://twitter.com/AbismoStudios" target="_blank" icon>
					<v-icon>mdi-twitter</v-icon>
				</v-btn>
			</div>
		</v-navigation-drawer>
		<div class="header-fake"></div>
	</header>
</template>

<script>
export default {
	name: "Appbar",
	data() {
		return {
			menu: false,
			menuLinks: [
				{
					title: "About",
					href: "/about",
					name: "AboutPage",
				},
				{
					title: "Projects",
					href: "/projects",
					name: "ProjectsPage",
				},
				{
					title: "News",
					href: "/news",
					name: "NewsPage",
				},
				{ 
					title: "Contact", 
					href: "/contact", 
					name: "ContactPage" 
				},
			],
		};
	},
	methods: {
		getNameRoute(name) {
			return this.$route.name == name ? true : false;
		},
		homePage() {
			if (this.$route.path != "/") {
				this.$router.push("/");
			}
		},
	}
};
</script>

<style scoped>
.header-fake {
	width: 100%;
	height: 104px;
	display: block;
}

.navbar-header {
	top: 0px;
	left: 0px;
	right: 0px;
	display: flex;
	width: 100%;
	background: rgb(27, 27, 27);
	position: fixed;
	z-index: 6;
}

.navbar-header > .container {
	padding: 0px;
	margin: 0px auto;
}

.navbar-header > .container > .row {
	margin: 0px;
	height: 100%;
}

.navbar-header > .container > .row > .col {
	padding: 0px;
	height: 100px;
}

.navbar-header > .container > .row > .col > div {
	height: 100px;
}

.navbar-header-title {
	display: flex;
}

.navbar-header-title > img {
	height: 80px;
	margin: auto 0;
	cursor: pointer;
}

.navbar-header-rede-links {
	justify-content: flex-end;
	display: flex;
}

.navbar-header-links {
	display: flex;
	height: 100%;
	color: #fff;
	justify-content: center;
	align-content: center;
	align-items: center;
	align-self: center;
}

.navbar-header-links > button {
	margin-right: 1.6em;
}

.navbar-header-links > div > a {
	padding: 0.6em 1em;
	color: #777 !important;
	font-weight: bold;
	text-decoration: none;
	background: transparent;
	margin: 0 0.3em;
	transition: 0.4s;
}

.navbar-header-links > div > a.active {
	color: #fff !important;
}

.navbar-header-links > div > a:hover {
	color: #fff !important;
	transition: 0.4s;
}
</style>