import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    footer: true
  },
  mutations: {
  },
  actions: {
    toggle(commit, action) {
      if (action != null) {
        this.state.footer = action;
        return;
      }

      this.state.footer = !this.state.footer;
    }
  },
  modules: {
  }
})
